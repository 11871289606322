import React, { Component } from 'react'
import DynamicComponent from "./DynamicComponent"
import SbEditable from 'storyblok-react'
import CloudinaryVideo from './cloudinaryvideoembed'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Footer from './Footer'

class Testimonials extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    // gsap.utils.toArray('.testimonials-row__text').forEach(gridOfDotsEl => {
    //   gsap.from(gridOfDotsEl, {
    //     y: 50,
    //     opacity: 0,
    //     scrollTrigger: {
    //       trigger: gridOfDotsEl,
    //       start: "top 50%",
    //       end: () => "+=100",
    //       scrub: 0.5
    //     },
    //   });
    // });

    gsap.utils.toArray('.testimonials-row').forEach(testimonialsImg => {
      gsap.from(testimonialsImg, {
        y: 100,
        opacity: 0,
        scrollTrigger: {
          trigger: testimonialsImg,
          start: "top 60%",
          end: () => "+=100",
          scrub: 0.5
        },
      });
    });
  }

  render() {
    const blok = this.props.blok
    const content =
      blok.body &&
      blok.body.map(childBlok => <DynamicComponent blok={childBlok} key={childBlok._uid}/>)
    return (
      <SbEditable content={blok}>
        {(blok.BackgroundVideo) ?
          <div className="bg-video">
            <CloudinaryVideo
              publicId={`ykk/${blok.BackgroundVideo}`}
              class_list="cover-bg"
              autoPlay={true}
            />
          </div>
          : ''}

        <div id="scroller">
          { content }
          <Footer />
        </div>
      </SbEditable>
    )
  }

}

export default Testimonials
